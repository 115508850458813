import { createTheme } from '@mui/material/styles';

import colors from './assets/scss/theme-variables.module.scss';

const theme = createTheme({
    palette: {
        primary: {
            main: colors.primaryMain,
            light: colors.primaryLight,
        },
        secondary: {
            main: colors.secondaryMain,
        },
        common: {
            grey: colors.greyColor,
            white: colors.whiteColor,
            whiteSmoke: colors.whitesmokeColor,
            platinum: colors.platinum,
            silverGrey: colors.oldSilverGrey,
        },
        button: {
            buttonDisableBackgroundColor: colors.buttonDisableBackgroundColor,
        },
        download: {
            link: colors.downloadLink,
        },
        status: {
            active: colors.active,
            inactive: colors.inactive,
        },
        uploadhistorystatus: {
            inProgress: colors.inprogress,
            completed: colors.completed,
            verified: colors.verified,
            rejected: colors.rejected,
            deleted: colors.deleted,
        },
    },
    typography: {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: 'Metropolis-Regular',
    },
    spacing: (factor) => `${0.25 * factor}rem`, // Custom spacing function
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        background: colors.disableButtonBgColor,
                        color: colors.disableButtonColor,
                        borderColor: colors.disableButtonBgColor,
                        cursor: 'no-drop',
                        pointerEvents: 'auto',
                    },
                    '&.Mui-disabled:hover': {
                        background: colors.disableButtonBgColor,
                        color: colors.disableButtonColor,
                    },
                },
            },
        },
    },
});

export { theme };

import * as React from 'react';
const Upload = (props) => (
    <svg
        width={20}
        height={26}
        viewBox="0 0 20 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M0 0V26H20V6.6L19.7 6.3L13.7 0.3L13.4 0H0ZM2 2H12V8H18V24H2V2ZM14 3.4L16.6 6H14V3.4ZM10 10L6 14H9V19H11V14H14L10 10ZM6 20V22H14V20H6Z" />
    </svg>
);
export default Upload;

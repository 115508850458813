import NoPageFound from '../components/NoPageFound';
import ChangePasswordPage from '../pages/lazy-loader/ChangePassword';
import LoginPage from '../pages/lazy-loader/Login';
import ReportsPage from '../pages/lazy-loader/Reports';
import UploadPage from '../pages/lazy-loader/Upload';
import UserCreationPage from '../pages/lazy-loader/UserCreation';

const routes = [
    {
        path: '/',
        component: LoginPage,
        exact: true,
        roles: [],
    },
    {
        path: '/login/*',
        component: LoginPage,
        exact: false,
        roles: [],
    },
    {
        path: '/change-password/*',
        component: ChangePasswordPage,
        exact: false,
        roles: [],
    },
    {
        path: '/reports/*',
        component: ReportsPage,
        exact: false,
        roles: ['businessuser', 'portfolioadmin'],
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: '/upload/*',
        component: UploadPage,
        exact: false,
        roles: ['portfolioadmin'],
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: '/user-creation/*',
        component: UserCreationPage,
        exact: false,
        roles: ['nstadmin'],
        private: true,
        showHeaderSidebar: true,
    },
    {
        path: '*',
        component: NoPageFound,
        exact: true,
        roles: [],
    },
];

export default routes;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';
import { Tooltip } from '@mui/material';

import Reports from '../assets/svg/Reports';
import Upload from '../assets/svg/Upload';
import UserCreationIcon from '../assets/svg/UserCreationIcon';
import { selectUserInfo } from '../features/login/redux/selector';
import { navigatePath } from '../redux/slice';
import { theme } from '../theme.js';

const tabDiv1 = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
};
const tabDiv2 = {
    zIndex: 1,
};
const tabStyle = {
    margin: '10px 0px',
    '&.Mui-selected': {
        backgroundColor: 'transparent',
    },
};
const customIndicatorStyle = {
    backgroundColor: theme.palette.secondary.main,
    width: '37px',
    height: '40px',
    display: 'flex',
    borderRadius: '8px',
    right: 'auto',
};

const isAuthenticated = (data, roles) => {
    return data && roles?.includes(data);
};

const Sidebar = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userData = useSelector(selectUserInfo);
    const mainRoute = location.pathname;
    const [valueIndex, setValueIndex] = useState({});

    const setThemeOnSelect = (pathName) => {
        return mainRoute === pathName
            ? theme.palette.primary.main
            : theme.palette.common.grey;
    };

    const tabData = [
        {
            title: 'User Creation',
            path: '/user-creation',
            icon: (
                <UserCreationIcon
                    fill={setThemeOnSelect('/user-creation')}
                    width={20}
                    height={20}
                />
            ),
            role: ['nstadmin'],
        },
        {
            title: 'Upload',
            path: '/upload',
            icon: (
                <Upload fill={setThemeOnSelect('/upload')} width={20} height={20} />
            ),
            role: ['portfolioadmin'],
        },
        {
            title: 'Reports',
            path: '/reports',
            icon: (
                <Reports
                    fill={setThemeOnSelect('/reports')}
                    width={24}
                    height={20}
                />
            ),
            role: ['businessuser', 'portfolioadmin'],
        },
    ];

    const tabsProps = {
        orientation: 'vertical',
        variant: 'scrollable',
        scrollButtons: false,
        'aria-label': 'Vertical tabs',
        TabIndicatorProps: { style: customIndicatorStyle },
    };

    useEffect(() => {
        const arr = tabData.filter((value) => {
            return value?.role?.includes(userData.role);
        });

        const obj = {};

        arr.forEach((element, index) => {
            obj[`${element.path}`] = index;
        });
        setValueIndex(obj);
    }, [userData, mainRoute]);

    return (
        <div className="sidebar-wrapper">
            <Tabs
                {...tabsProps}
                sx={{ height: 'inherit' }}
                className="sidebar-tabs-wrapper"
                value={valueIndex[mainRoute] ? valueIndex[mainRoute] : 0}
            >
                {tabData.map((tab, index) => {
                    if (isAuthenticated(userData?.role, tab.role)) {
                        return (
                            <Tooltip
                                key={index}
                                title={tabData[index].title}
                                placement="right"
                                arrow
                            >
                                <Tab
                                    key={index}
                                    value={index}
                                    icon={
                                        <div style={tabDiv1}>
                                            <div style={tabDiv2}>{tab.icon}</div>
                                        </div>
                                    }
                                    sx={tabStyle}
                                    aria-label={tab.text}
                                    disableRipple
                                    className="sidebar-tabs"
                                    onClick={() => {
                                        navigate(tab.path);
                                        dispatch(navigatePath(tab.path));
                                    }}
                                />
                            </Tooltip>
                        );
                    }
                })}
            </Tabs>
        </div>
    );
});
Sidebar.displayName = 'Sidebar';
export default Sidebar;

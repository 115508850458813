/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

import {
    getCenterList,
    getDeliveryPartnerList,
    getGroupLifecycleMasterLevel,
    getLoanProductList,
    getRegionList,
} from './thunks';
const initialState = {
    path: '/Dashboard',
    data: null,
    regionList: [],
    centerList: [],
    loanProductList: null,
    deliveryPartnerList: null,
    filterPayload: null,
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        navigatePath: (state, action) => {
            state.path = '/' + action.payload?.text;
        },
        setFilterPayload: (state, action) => {
            state.filterPayload = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getGroupLifecycleMasterLevel.fulfilled, (state, action) => {
            state.masterData.lifecycle.groupLifeCycleMasterLevel.loading = false;
            state.masterData.lifecycle.groupLifeCycleMasterLevel.data =
                action.payload;
        });
    },
});

// Action creators are generated for each case reducer function
export const { navigatePath, setFilterPayload } = globalSlice.actions;

export default globalSlice.reducer;

import React from 'react';

import Toolbar from '@mui/material/Toolbar';

import FederalLogo from '../assets/svg/FederalLogo.svg';
import HeaderPopper from './HeaderPopper';

const toolbarStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
};
const Header = () => {
    return (
        <Toolbar sx={toolbarStyle}>
            <div style={{ paddingLeft: '1.2rem' }}>
                <img src={FederalLogo} className="app-logo" alt="logo" />
            </div>
            <HeaderPopper />
        </Toolbar>
    );
};

export default Header;

import { createSlice } from '@reduxjs/toolkit';

import {
    getFOList,
    getMainGraphData,
    getNpaMainGraphData,
    getNPAReportRegularized,
    getNpaSideGraphData,
    getNpaTableView,
    getSideGraphData,
    getSMAReport,
    getSmaReportList,
} from './thunks';

const initialState = {
    data: null,
    drawerOpen: false,
    smaReports: {},
    smaReportsList: [],
    mainGraphData: null,
    sideGraphData: null,
    selectedTabValue: 'SMA',
    foList: null,
};

export const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        openDrawer: (state) => {
            state.drawerOpen = true;
        },
        closeDrawer: (state) => {
            state.drawerOpen = false;
        },
        updateTabValue: (state, action) => {
            state.selectedTabValue = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSMAReport.fulfilled, (state, action) => {
                state.smaReports = action.payload;
            })
            .addCase(getNpaTableView.fulfilled, (state, action) => {
                state.smaReports = action.payload;
            })
            .addCase(getSmaReportList.fulfilled, (state, action) => {
                state.smaReportsList = action.payload;
            })
            .addCase(getMainGraphData.rejected, (state) => {
                state.mainGraphData = null;
            })
            .addCase(getMainGraphData.fulfilled, (state, action) => {
                state.mainGraphData = action.payload;
            })
            .addCase(getSideGraphData.rejected, (state) => {
                state.sideGraphData = null;
            })
            .addCase(getSideGraphData.fulfilled, (state, action) => {
                state.sideGraphData = action.payload;
            })
            .addCase(getNpaMainGraphData.rejected, (state) => {
                state.mainGraphData = null;
            })
            .addCase(getNpaMainGraphData.fulfilled, (state, action) => {
                state.mainGraphData = action.payload;
            })
            .addCase(getNPAReportRegularized.rejected, (state) => {
                state.mainGraphData = null;
            })
            .addCase(getNPAReportRegularized.fulfilled, (state, action) => {
                state.mainGraphData = action.payload;
            })
            .addCase(getNpaSideGraphData.rejected, (state) => {
                state.sideGraphData = null;
            })
            .addCase(getNpaSideGraphData.fulfilled, (state, action) => {
                state.sideGraphData = action.payload;
            })
            .addCase(getFOList.rejected, (state) => {
                state.foList = null;
            })
            .addCase(getFOList.fulfilled, (state, action) => {
                state.foList = action.payload;
            });
    },
});

// Action creators are generated for each case reducer function
export const { openDrawer, closeDrawer, updateTabValue } = reportSlice.actions;

export default reportSlice.reducer;

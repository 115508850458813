import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';

import image from '../assets/svg/userIcon.webp';
import { selectUserInfo } from '../features/login/redux/selector';
import { DESTROY_SESSION } from '../store/types';
import { theme } from '../theme';
import convertTextFormat from '../utils/TextTransform';

const buttonContainer = {
    minWidth: 'max-content',
    maxWidth: '8rem',
    backgroundColor: theme.palette.primary.light,
    p: 2,
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    borderRadius: 1,
    cursor: 'pointer',
    padding: '0.3rem 0.5rem',
};
const imgStyle = {
    display: 'flex',
    width: '23px',
    height: '23px',
    aspectRatio: '1/1',
    borderRadius: '50%',
};
const userTextStyle = {
    fontSize: '1rem',
    maxWidth: '8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'Capitalize',
    userSelect: 'none',
    color: theme.palette.common.superBlack,
    fontWeight: '700',
};
const headerWrapperStyle = {
    width: 'max-content',
    marginRight: '2.5rem',
    display: 'flex',
    justifyContent: 'right',
    height: '2.5rem',
};
const HeaderPopper = () => {
    const userInfo = useSelector(selectUserInfo);
    const firstName = window.sessionStorage.getItem('firstName');
    const role = window.sessionStorage.getItem('role');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const ellipsisRef = React.useRef();
    const [showTooltip, setShowTooltip] = React.useState(false);

    useEffect(() => {
        const container = ellipsisRef.current;

        if (container?.scrollWidth > container?.clientWidth) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    }, [userInfo?.userName]);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = async () => {
        navigate('/login');
        window.sessionStorage.clear();
        dispatch({ type: DESTROY_SESSION });
    };
    const handleChangePassword = () => {
        navigate('/change-password');
    };
    return (
        <>
            <Box sx={headerWrapperStyle}>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ marginTop: '0.5rem' }}
                >
                    <MenuItem>{convertTextFormat(role, 'PASCAL_CASE')}</MenuItem>
                    <MenuItem onClick={handleChangePassword}>
                        Change Password
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
                <Tooltip
                    title={showTooltip ? firstName : ''}
                    placement="bottom"
                    arrow
                >
                    <Box sx={buttonContainer} onClick={handleClick}>
                        <Box>
                            <img src={image} alt="logo" style={imgStyle} />
                        </Box>
                        <Typography sx={userTextStyle} ref={ellipsisRef}>
                            {firstName || 'Admin'}
                        </Typography>
                    </Box>
                </Tooltip>
            </Box>
        </>
    );
};

export default HeaderPopper;

/**
 * Asynchronously loads the component for HomePage
 */

import loadable from '../../utils/loadable';

const UserCreationPage = loadable(() => import('../UserCreation'), {
    fallback: <div className="flex-center">Loading User Creation Page..</div>,
});

export default UserCreationPage;

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import PropTypes from 'prop-types';

import { showLoader } from '../../redux/commonSlice';
import { selectLoader } from '../../redux/selector';
import GlobalLoader from './GlobalLoader';
import LocalLoader from './LocalLoader';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'none',
    p: 4,
    outline: 'none',
};

export default function CustomLoader({ loaderType = 'globalLoader' }) {
    const dispatch = useDispatch();
    const loaderState = useSelector(selectLoader);

    const handleClose = () => {
        if (loaderState?.loaderSkippable) {
            dispatch(showLoader());
        }
    };

    React.useEffect(() => {
        const handleEscKey = (event) => {
            if (event.keyCode === 'Escape' || event.key === 'Backspace') {
                if (loaderState?.loaderSkippable) {
                    dispatch(showLoader());
                }
            } else {
                event.preventDefault();
            }
        };

        if (loaderState?.showLoader) {
            document.addEventListener('keydown', handleEscKey);
        }

        // const timeoutId = setTimeout(() => {
        //     if (loaderState?.showLoader) {
        //         dispatch(showLoader({ loader: false, allowClose: true }));
        //         dispatch(activeApiCountChange({ type: 'reset' }));
        //     }
        // }, 20000);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
            // clearTimeout(timeoutId);
        };
    }, [dispatch, loaderState]);

    return (
        <div>
            <Modal
                open={loaderState?.showLoader}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {loaderType === 'globalLoader' ? (
                        <GlobalLoader />
                    ) : (
                        <LocalLoader />
                    )}
                </Box>
            </Modal>
        </div>
    );
}

CustomLoader.propTypes = {
    loaderType: PropTypes.string,
};

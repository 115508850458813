import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';
import { buildDynamicURL } from '../../../utils/utils';

export const uploadFilesHistory = createAsyncThunk(
    'DATA_FETCH/UPLOAD_HISTORY',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`/importdata`, values);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getImportedHistory = createAsyncThunk(
    'DATA_FETCH/IMPORT_HISTORY',
    async (values, { rejectWithValue }) => {
        try {
            const url = buildDynamicURL('importdata', values, {
                skip: 0,
                limit: 10,
            });
            var response = await apiService.get(url);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadDemandExcel = createAsyncThunk(
    'download_Demand_Excel',
    async (value, { rejectWithValue }) => {
        try {
            var response = await apiService.get(
                `/importdata/download?historyId=${value}`,
                { responseType: 'blob' }
            );
            const href = URL.createObjectURL(response?.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `Validated-file.xlsx`);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateStatus = createAsyncThunk(
    'DATA_FETCH/UPDATE_STATUS',
    async (payload, { rejectWithValue }) => {
        try {
            var response = await apiService.post(
                `/importdata/updateStatus`,
                payload
            );
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteFiles = createAsyncThunk(
    'DATA_FETCH/DELETE_FILES',
    async (payload, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`/importdata/deletedata`, payload);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

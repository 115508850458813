import { createSlice } from '@reduxjs/toolkit';

import { getUserList, getUserRoles } from './thunks';

const initialState = {
    userList: null,
    userRoles: null,
    drawerOpen: false,
    currentPage: 1,
    totalResults: 0,
    limit: 10,
    skip: 0,
    rowsPerPage: [5, 10, 25, 50, 100],
    error: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        openDrawer: (state) => {
            state.drawerOpen = true;
        },
        closeDrawer: (state) => {
            state.drawerOpen = false;
        },
        pageChange(state, action) {
            state.currentPage = action.payload;
            state.skip = (action.payload - 1) * state.limit;
        },
        rowsPerPageChange(state, action) {
            state.skip = 0;
            state.currentPage = 1;
            state.limit = action.payload;
        },
        updateTotalResults(state, action) {
            state.totalResults = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserList.fulfilled, (state, action) => {
                state.userList = action.payload;
            })
            .addCase(getUserList.rejected, (state) => {
                state.userList = null;
            })
            .addCase(getUserRoles.fulfilled, (state, action) => {
                state.userRoles = action.payload;
            })
            .addCase(getUserRoles.rejected, (state) => {
                state.userRoles = null;
            });
    },
});

export const {
    openDrawer,
    closeDrawer,
    pageChange,
    rowsPerPageChange,
    updateTotalResults,
} = userSlice.actions;

export default userSlice.reducer;

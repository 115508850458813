/**
 * Asynchronously loads the component for ChangePasswordPage
 */

import loadable from '../../utils/loadable';

const ChangePasswordPage = loadable(() => import('../ChangePassword'), {
    fallback: <div className="flex-center">Change Password loading..</div>,
});

export default ChangePasswordPage;

import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';

export const getSMAReport = createAsyncThunk(
    'SMA_REPORTS',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `/smareport/tableview`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getNpaTableView = createAsyncThunk(
    'NPA_TABLE_VIEW',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                reportingSMA: 'SMA3',
            };
            if ('displayUnit' in updatedValues) {
                delete updatedValues.displayUnit;
            }

            var response = await apiService.post(
                `/smareport/npa/tableview`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getSmaReportList = createAsyncThunk(
    'Sma_Report',
    async (_, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`/smareport/filter/sma`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getMainGraphData = createAsyncThunk(
    'MAIN_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `/smareport/maingraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getSideGraphData = createAsyncThunk(
    'SIDE_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `/smareport/sidegraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//API to get data category wise(SMA0,SMA1,SMA2,SMA3)
export const getNPAReportRegularized = createAsyncThunk(
    'NPA_REPORT_REGULARIZED',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `/smareport/smamaingraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getNpaMainGraphData = createAsyncThunk(
    'NPA_MAIN_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `/smareport/npa/maingraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getNpaSideGraphData = createAsyncThunk(
    'NPA_SIDE_GRAPH_DATA',
    async (values, { rejectWithValue }) => {
        try {
            const updatedValues = {
                ...values,
                displayUnit: values?.displayUnit || 'count',
            };
            var response = await apiService.post(
                `/smareport/npa/sidegraph`,
                updatedValues
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadCXReport = createAsyncThunk(
    'DOWNLOAD_CX_REPORT',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(
                `/report/download/maintable`,
                values,
                {
                    responseType: 'blob',
                }
            );
            const href = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `CX-Report.xlsx`);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadNPACXReport = createAsyncThunk(
    'DOWNLOAD_CX_REPORT',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(
                `/report/npa/download/maintable`,
                values,
                {
                    responseType: 'blob',
                }
            );
            const href = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `CX-Report.xlsx`);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getFOList = createAsyncThunk(
    'FO_LIST',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`/smareport/filter/fo`, values);
            return response?.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

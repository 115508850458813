import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loader: {
        showLoader: false,
        loaderSkippable: true,
    },
    snackBar: {
        snackBarOpen: false,
        allowClose: true,
        vertical: 'bottom',
        horizontal: 'right',
        duration: 5000,
        severity: null,
        snackBarMessage: 'Text',
        color: null,
    },
    activeApiCount: null,
};

export const commmonSlice = createSlice({
    name: 'commonSlice',
    initialState,
    reducers: {
        showLoader: (state, action) => {
            const { loader, allowClose } = action.payload || {};
            state.loader.showLoader = loader || false;
            state.loader.loaderSkippable = allowClose || true;
        },
        showSnackBar: (state, action) => {
            state.snackBar = action.payload;
        },
        activeApiCountChange: (state, action) => {
            const { type, skippable } = action.payload || {};
            if (type === 'increment') {
                state.activeApiCount += 1;
            } else if (type === 'decrement') {
                state.activeApiCount -= 1;
            } else if (type === 'reset') {
                state.activeApiCount = 0;
            }
            state.loader.showLoader = state.activeApiCount > 0 ? true : false;
            state.loader.loaderSkippable = skippable;
        },
    },
});

// Action creators are generated for each case reducer function
export const { showLoader, showSnackBar, activeApiCountChange } =
    commmonSlice.actions;

export default commmonSlice.reducer;

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';

import { PersistGate } from 'redux-persist/integration/react';

import CustomLoader from './components/loader/CustomLoader';
import CustomSnackbar from './components/snackbar/CustomSnackbar';
import routes from './routes/index';
import { persistor, store } from './store';
import { theme } from './theme';
import generateRoutes from './utils/generateRoutes';

import './assets/scss/style.scss';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <CustomSnackbar />
                        <CustomLoader />
                        <Routes>{generateRoutes(routes)}</Routes>
                    </Router>
                </PersistGate>
            </Provider>
        </ThemeProvider>
    );
};

export default App;

export const allowedSkippableLoaders = [
    //example to add skippable for loader
    // { type: 'upload/updateTotalResults', allowLoaderSkip: false },
];
export const allowedSnackBarActionTypes = [
    {
        type: 'DATA_FETCH/UPLOAD_HISTORY/fulfilled',
        showSnackbarFor: 'success',
    },
    {
        type: 'DATA_FETCH/IMPORT_HISTORY/fulfilled',
        showSnackbarFor: 'success',
        message: 'Upload History Updated',
    },
    { type: 'DATA_FETCH/UPDATE_STATUS/fulfilled', showSnackbarFor: 'success' },
    { type: 'USER/BLOCK_USER/fulfilled', showSnackbarFor: 'success' },
    { type: 'USER/CREATE_USER/fulfilled', showSnackbarFor: 'success' },
    { type: 'USER/CREATE_USER/rejected', showSnackbarFor: 'failure' },
    { type: 'USER/UPDATE_USER/fulfilled', showSnackbarFor: 'success' },
    { type: 'USER/UPDATE_USER/rejected', showSnackbarFor: 'failure' },
    { type: 'USER/RESET_PASSWORD/fulfilled', showSnackbarFor: 'success' },
    { type: 'USER/RESET_PASSWORD/rejected', showSnackbarFor: 'failure' },
];

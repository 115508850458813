// apiService.js
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
const apiService = axios.create();
apiService.interceptors.request.use(
    (config) => {
        const loginToken = window.sessionStorage.getItem('token');
        // const refToken = window.sessionStorage.getItem('rT');

        const authConfig = {
            ...config,
            headers: {
                ...config.headers,
            },
        };
        const token =
            config.url === '/auth/v2/auth/logout' ? loginToken : loginToken;
        if (token) {
            authConfig.headers.Authorization = `Bearer ${token}`;
        }
        return authConfig;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiService.interceptors.response.use(
    (response) => {
        return response?.data;
    },
    (error) => {
        if (error?.response?.status === 401) {
            window.sessionStorage.clear();
            window.location.href = '/login';
            window.localStorage.setItem('API_error_with_401', JSON.stringify(error));
        }
        return Promise.reject(error);
    }
);

export default apiService;

import { createSelector } from 'reselect';

const global = (state) => state.global;
const common = (state) => state.common;

export const selectPath = createSelector([global], (state) => state.path);
export const selectLoader = createSelector([common], (state) => state?.loader);
export const selectSnackBar = createSelector([common], (state) => state?.snackBar);
export const selectFilterPayload = createSelector(
    [global],
    (state) => state.filterPayload
);
export const selectGroupLifeCycleMasterLevel = createSelector(
    [global],
    (state) => state?.masterData?.lifecycle?.groupLifeCycleMasterLevel?.data
);

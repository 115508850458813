import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { cleardata } from '../features/login/redux/slice';

const isAuthenticated = (data, roles) => {
    return data && roles?.includes(data?.userInfo?.role);
};

const PrivateRoute = ({ element, roles }) => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.login.data);

    if (isAuthenticated(userData?.data, roles)) {
        return element;
    } else {
        window.sessionStorage.clear();
        dispatch(cleardata());
        return <Navigate to="/login" />;
    }
};

export default PrivateRoute;
PrivateRoute.propTypes = {
    element: PropTypes.element.isRequired,
    roles: PropTypes.array.isRequired,
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import { showSnackBar } from '../../redux/commonSlice';
import { selectSnackBar } from '../../redux/selector';
import Alert from './Alert';

const snackBarCloseState = {
    snackBarOpen: false,
    allowClose: true,
    vertical: '',
    horizontal: '',
    duration: '',
    severity: '',
    text: '',
    color: '',
};

const CustomSnackbar = () => {
    const dispatch = useDispatch();
    const {
        allowClose,
        color,
        duration,
        horizontal,
        severity,
        snackBarOpen,
        snackBarMessage,
        vertical,
    } = useSelector(selectSnackBar);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        } else if (reason === 'timeout') {
            dispatch(showSnackBar(snackBarCloseState));
        } else if (allowClose) {
            dispatch(showSnackBar(snackBarCloseState));
        }
    };

    return (
        <Box>
            {snackBarOpen && (
                <Snackbar
                    open={snackBarOpen}
                    autoHideDuration={duration}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: vertical || '',
                        horizontal: horizontal || '',
                    }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{
                            width: '23rem',
                            backgroundColor: color || 'none',
                        }}
                    >
                        {snackBarMessage}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default CustomSnackbar;

import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../../../service/apiService';
import { buildDynamicURL } from '../../../utils/utils';

export const getUserList = createAsyncThunk(
    'USER/USER_LIST',
    async (values, { rejectWithValue }) => {
        try {
            const url = buildDynamicURL('user/list', values, {
                skip: 0,
                limit: 10,
            });
            var response = await apiService.get(url);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const changeUserStatus = createAsyncThunk(
    'USER/BLOCK_USER',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.put(`/user/status`, values);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'USER/RESET_PASSWORD',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`/user/resetpassword`, values);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getUserRoles = createAsyncThunk(
    'USER/USER_ROLES',
    async (_, { rejectWithValue }) => {
        try {
            var response = await apiService.get(`/user/create/role`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const createNewUser = createAsyncThunk(
    'USER/CREATE_USER',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.post(`/user/create`, values);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateUser = createAsyncThunk(
    'USER/UPDATE_USER',
    async (values, { rejectWithValue }) => {
        try {
            var response = await apiService.put(`/user/edituser`, values);
            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

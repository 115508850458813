import dayjs from 'dayjs';
import download from 'downloadjs';
import html2canvas from 'html2canvas';

export const getLastMonthsFromAndToDate = (endDate, monthsCount) => {
    const endingDate = endDate ? dayjs(endDate) : dayjs();
    const monthsAgo = endingDate.subtract(monthsCount, 'months');
    return {
        fromDate: monthsAgo,
        toDate: endingDate,
    };
};

export const getFromDateBasedOnToDate = (endDate, comparisonDate, dateList) => {
    const targetDate = getLastMonthsFromAndToDate(endDate, 1);
    const formattedTargerDate = comparisonDate
        ? new Date(comparisonDate)
        : new Date(targetDate?.fromDate);
    const targetTime = formattedTargerDate.getTime();
    const timeDifferences = dateList?.map((date) =>
        Math.abs(new Date(date).getTime() - targetTime)
    );
    const minIndex = timeDifferences?.indexOf(Math.min(...timeDifferences));
    return new Date(dateList[minIndex]);
};

export const downloadScrollableContent = async (domElement) => {
    if (domElement) {
        window.scrollTo(0, 0);
        html2canvas(domElement, {
            scrollX: 0,
            scrollY: 0,
        }).then((canvas) => {
            const dataUrl = canvas.toDataURL('image/png');
            download(dataUrl, 'reports.png', 'image/png');
        });
    }
};

export const dateFormatter = (dateValue) => {
    return `${dateValue?.$y}-${(dateValue?.$M + 1)
        ?.toString()
        ?.padStart(2, '0')}-${dateValue?.$D?.toString()?.padStart(2, '0')}`;
};

export const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        const result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
};

export const buildDynamicURL = (endpoint, queryParams, defaultValues) => {
    const queryString = [];
    for (const key in queryParams) {
        if (key in queryParams) {
            if (
                queryParams[key] !== null &&
                queryParams[key] !== undefined &&
                queryParams[key] !== ''
            ) {
                queryString.push(`${key}=${queryParams[key]}`);
            } else if (key in defaultValues) {
                queryString.push(`${key}=${defaultValues[key]}`);
            }
        }
    }
    const url = `${endpoint}?${queryString.join('&')}`;

    return url;
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import apiService from '../service/apiService';

export const getGroupLifecycleMasterLevel = createAsyncThunk(
    'GROUP_LIFECYCLE_MASTER_LEVEL',
    async () => {
        try {
            const response = await apiService.get(
                '/lifecycle/v1/group/master/levels'
            );
            return response;
        } catch (error) {
            return error;
        }
    }
);
